ion-card-title,
ion-title,
ion-button {
    font-family: var(--default-font);
    font-weight: bold;
}

ion-back-button {
    height: 42px;
    .my-back-button {
        content: url("http://cdn.mysitemyway.com/etc-mysitemyway/icons/legacy-previews/icons/black-ink-grunge-stamps-textures-icons-arrows/003683-black-ink-grunge-stamp-textures-icon-arrows-double-arrowhead-left.png");
        width: 48px;
    }
}

ion-title {
    font-size: 1.4rem;
}

.card-content-ios {
    font-family: var(--default-font);

    h2 {
        font-size: 1.4rem;
        line-height: 1.8;
    }

    h3 {
        font-size: 1.2rem;
    }

    h4 {
        font-size: 1rem;
    }

    h5 {
        font-size: 0.8rem;
    }

    h4 {
        font-size: 0.6rem;
    }
}

ion-header {
    &::after {
        background: none;
    }

    ion-toolbar {
        --border-width: 0 !important;

        ion-buttons ion-back-button {
            --color: var(--text-black) !important;
        }
    }
}

ion-label {
    font-family: var(--default-font) !important;
    font-weight: bold;
}

ion-footer {
    // background: linear-gradient(0deg, #bbe8ba 0%, rgba(187, 232, 186, 0) 100%);
    padding: 0;
    position: absolute;
    bottom: 30px;
    height: 80px;
    padding: 0 25px 0;

    &::before {
        display: none;
    }

    ion-button {
        font-family: var(--default-font) !important;
        font-weight: bold;
        font-size: 20px !important;
        width: 160px;
        height: 62px;
        // height: 42px !important;
        // display: flex;
        // justify-content: center;
    }

    ion-toolbar {
        --background: transparent;
    }
}

ion-ripple-effect {
    display: none !important;
}

ion-toolbar {
    --background-color: transparent;
    --ion-color-base: transparent !important;
}

ion-content {
    --ion-background-color: #e5e5e5;

    &.background-image {
        --background: #d9f2da url("../../assets/background/page-image-bg.png") no-repeat bottom center / cover;
    }

    &.background-image-2 {
        --background: #d9f2da url("../../assets/background/page-image-bg2.png") no-repeat bottom center / contain;
    }

    &.background-image-3 {
        --background: #d9f2da url("../../assets/background/page-image-bg3.png") no-repeat bottom center / cover;
    }
}

ion-icon {
    zoom: 0.8;
    color: var(--color-brand);
}

ion-checkbox {
    // --background: #fff;
    --background: #f1f1f1;
    --background-checked: var(--color-brand);

    --border-color: #fff;
    --border-color-checked: var(--color-brand);

    --border-radius: 50%;

    --checkmark-color: #fff;
    --checkmark-width: 2px;

    --size: 28px;

    padding: 2px;
    margin-right: 10px;

    position: relative;
}

.checkbox-icon path {
    opacity: 1 !important;
}

ion-segment {
    width: 100%;
    height: 46px;
    // background: var(--color-brand-extra-light);
    background: transparent;
    border-radius: 8px;
    margin-bottom: 20px;

    ion-segment-button {
        margin: 0;
        --indicator-color: transparent;
        border-bottom: 2px solid var(--color-brand-extra-light);

        &:first-of-type {
            // border-top-left-radius: 20px;
        }

        &[aria-selected="true"] {
            box-shadow: none;
            border-radius: 0;
            background: var(--color-brand-extra-light);
            border-bottom: 2px solid var(--color-brand);
            --border-radius: 0;
            --border-color: transparent;
            --indicator-box-shadow: none;
            --border-width: 0;
        }

        ion-label {
            font-size: 20px;
            line-height: 22px;
            color: var(--color-brand-dark);
        }
    }
}

.sc-ion-alert-ios-h {
    font-size: 20px;
}

[ion-popover] {
    --max-height: 200px;

    &::part(content) {
        --width: 95%;
    }

    ion-item.ion-focused::part(native)::after {
        opacity: 0 !important;
    }
}

.item-has-focus {
    --highlight-background: var(--color-brand);
    ion-label {
        color: #fff !important;
    }
}

.swiper-pagination * {
    opacity: 1;
}

.apexcharts-tooltip {
    * {
        color: #fff;
        font-weight: 400;
    }
    &.apexcharts-theme-light {
        background: var(--color-chart) !important;
        border: 0 !important;
        .apexcharts-tooltip-title {
            display: none !important;
        }
        .apexcharts-tooltip-marker {
            display: none !important;
        }
    }
}
