.modal-share-preview {
    --background: transparent;
    // --min-height: 600px;
    --height: 600px;

    &::part(backdrop) {
        background: rgba(51, 51, 51, 0.9);
    }

    .content {
        background: linear-gradient(180deg, #ffffff 0%, #ceecc5 100%);
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;

        h3 {
            color: var(--color-brand);
            margin: 0 0 30px;
        }

        .badge-large {
            width: 170px;
            margin-bottom: 30px;
            font-size: 20px;
            font-family: var(--font-default);
            font-weight: 500;
            white-space: nowrap;
        }

        .caption {
            font-size: 24px;
            margin: 0;
        }

        h2 {
            color: var(--color-brand);
            margin: 0 0 30px;
        }

        .score {
            display: block;
            color: #fc6dc9;
            font-size: 70px;
            text-align: center;
            line-height: 0.75;
            span {
                display: block;
                font-size: 16px;
            }
        }

        .note {
            text-align: center;
            margin-bottom: 0;
        }

        .actions {
            display: flex;
            justify-content: space-around;
            // margin-bottom: 30px;

            .btn-default {
                width: 120px;
                margin-right: 5px;
            }

            .btn-primary {
                width: 199px;
            }
        }
    }
}


.modal-list-picker {
    --background: transparent;
    // --min-height: 600px;
    // --height: 600px;

    &::part(backdrop) {
        background: rgba(51, 51, 51, 0.9);
    }

    ion-content {
        --ion-background-color: #fff !important;

        ion-searchbar {
            margin-top: 10px;
        }
    }
}