.h-15 {
    height: 15px;
}
.h-30 {
    height: 30px;
}
.h-100p {
    height: 100%;
}

.w-100 {
    width: 100px;
}
.w-100p {
    width: 100%;
}

.mt-0 {
    margin-top: 0 !important;
}
.mt-10 {
    margin-top: 10px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.d-flex {
    display: flex;

    &.flex-row {
        flex-direction: row !important;
    }

    &.flex-column {
        flex-direction: column !important;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-around {
        justify-content: space-around;
    }

    &.justify-between {
        justify-content: space-between;
    }

    &.align-center {
        align-items: center;
    }
}

.d-none {
    display: none !important;
}

.flex-grow {
    flex-grow: 1;
}



[brand] {
    color: var(--color-brand);
}

[uppercase] {
    text-transform: uppercase;
}

[padding] {
    padding: 16px;
}

[small] {
    font-size: 0.8em;
}

[nowrap] {
    white-space: nowrap;
}
